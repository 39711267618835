import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import Chart from "react-apexcharts";
import { formatLongNumbers, formatNumber } from "src/utils";
import { useTranslation } from "react-i18next";

const colors = ["#FF8B3D", "#344054", "#4D4DEC", "#067647"];

const LineChart = ({ data }: { data: any }) => {
  console.log("doto: ", { data });
  const theme = useTheme();
  const matchesMediumUp = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation("inventory");
  const { t: dashboardT } = useTranslation("dashboard");

  const daysArr: any = [];
  const directCostArr: any = [];
  const indirectCostArr: any = [];
  const totalCostArr: any = [];

  data.days.forEach((item: any) => {
    daysArr.push(moment(item.day).format(matchesMediumUp ? "D/M" : "DD / MM"));
    directCostArr.push(item.data.direct_cost);
    indirectCostArr.push(item.data.indirect_cost);
    totalCostArr.push(item.data.direct_cost + item.data.indirect_cost);
  });

  const chartData = {
    series: [
      {
        name: t("directCost"),
        data: directCostArr,
      },
      {
        name: t("indirectCost"),
        data: indirectCostArr,
      },
      {
        name: dashboardT("cogs"),
        data: totalCostArr,
      },
    ],
    options: {
      noData: {
        text: t("noData"),
        style: {
          fontSize: matchesMediumUp ? "15px" : "12px",
        },
      },
      chart: {
        id: "line-chart",
        toolbar: {
          show: false, // Set to false to hide the toolbar
        },
      },
      colors: colors, // Set the line color (in this case, red)
      xaxis: {
        categories: daysArr,
      },
      tooltip: {
        y: {
          formatter: (value: number): string =>
            formatNumber(+value)?.toString(),
        },
      },
      yaxis: {
        labels: {
          formatter: formatLongNumbers,
        },
      },
      markers: {
        size: 4, // Adjust the size of the marker
        // colors: colors, // Set the line color (in this case, red)
      },
      stroke: {
        width: 2,
      },
      legend: {
        show: true, // Set to false if you want to hide the legend
        fontSize: "15px", // Font size of the legend
        // offsetY: matchesSmallUp ? 15 : 0,
        markers: {
          width: 15,
          height: 15,
          strokeWidth: 0,
          radius: 2,
        },
        itemMargin: {
          horizontal: 0,
          vertical: matchesMediumUp ? 20 : 0,
        },
      },
    },
  };

  return (
    <Chart
      options={chartData.options}
      series={chartData.series}
      type="line"
      height={350}
      lineWidth={1}
    />
  );
};

export default LineChart;
