import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useGetPaymentMethods } from "src/api/generic";
import { LoadingButton } from "@mui/lab";
import FilePondUploader from "src/shared/components/file-pond-uploader";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useTranslation } from "react-i18next";
import {
  useAddPurchaseEventPaymentMutation,
  useAddPurchasePaymentMutation,
  useGetSupplierEvents,
} from "src/api/purchasing/purchasing";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const schema = yup.object().shape({
  type: yup
    .string()
    .required("Required")
    .oneOf(["payment", "event"] as const),
  amount: yup
    .string()
    .required("Required")
    .test(
      "min-value",
      "Must be greater than 0",
      (value) => parseFloat(value) > 0
    ),
  payment_method_id: yup.string().when("type", {
    is: (value: string) => value === "payment",
    then: (value) => value.required("Required"),
  }),
  date: yup.string().when("type", {
    is: (value: string) => value === "payment",
    then: (value) => value.required("Required"),
  }),
  eventId: yup.string().when("type", {
    is: (value: string) => value === "event",
    then: (value) => value.required("Required"),
  }),
});

export interface FormInputs extends yup.InferType<typeof schema> {}

interface IProps {
  open: boolean;
  handleClose: () => void;
  refetch: Function;
  row: any;
}

const AddPurchasePaymentPopup = (props: IProps) => {
  const { open, handleClose, refetch, row } = props;
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [files, setFiles] = useState<any>([]);
  const { t } = useTranslation("operationCost");
  const { t: purchasingT } = useTranslation("purchasing");
  const { t: generalT } = useTranslation("general");

  //React Hook Form
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      type: "payment",
    },
  });

  //APIS
  const { data: paymentsData } = useGetPaymentMethods();
  const { mutate, status, isPending, error } = useAddPurchasePaymentMutation();
  const {
    mutate: mutateEvent,
    status: statusEvent,
    isPending: isPendingEvent,
    error: errorEvent,
  } = useAddPurchaseEventPaymentMutation();

  const { data: supplierEventsData } = useGetSupplierEvents(row.supplier?.id);

  const supplierEventsList =
    supplierEventsData?.data.filter(
      (event: any) => event.operation === "advanced"
    ) || [];

  const purchaseEventCreditRemaining =
    supplierEventsList.find((item: any) => item.id === Number(watch("eventId")))
      ?.remaining ?? 0;

  const onClose = () => {
    handleClose();
    reset();
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    // validate not to exceed event remaining credit
    if (
      data.type === "event" &&
      data.amount &&
      +data.amount > purchaseEventCreditRemaining
    ) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: `${t("eventCredit")}: ${purchaseEventCreditRemaining}`,
        },
      ]);
      return;
    }

    const dataToSend: any = {
      purchase_id: row.id,
      amount: +data.amount,
      ...(data.type === "payment" && { date: data.date }),
      ...(data.type === "payment" && {
        payment_method_id: data.payment_method_id,
      }),
      ...(data.type === "event" && { purchase_event_id: data.eventId }),
    };
    const formData = new FormData();
    // append data
    for (const key in dataToSend) {
      formData.append(key, dataToSend[key]);
    }
    // append files
    if (files.length > 0) {
      files.forEach((file: any, index: number) => {
        formData.append(`files[${index}]`, file);
      });
    }
    if (data.type === "event") {
      mutateEvent(formData);
    } else {
      mutate(formData);
    }
  };

  //Add payment success and error handling
  useEffect(() => {
    if (status === "success") {
      refetch();
      onClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("addedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  //Add Event payment success and error handling
  useEffect(() => {
    if (statusEvent === "success") {
      refetch();
      onClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("addedSuccessfully"),
        },
      ]);
    } else if (statusEvent === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            errorEvent?.data.errors?.[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [statusEvent]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing={3}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "100%", sm: 547 },
          bgcolor: "#FFF",
          border: "1px solid #D0D5DD",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={0}
        >
          <Typography color={"#101828"} fontSize={"20px"}>
            {purchasingT("payment")}
          </Typography>
          <IconButton onClick={onClose} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <Stack direction={"row"} alignItems={"center"} gap={3}>
              <Typography>{purchasingT("paymentType")}</Typography>
              <FormControl
                fullWidth
                size="small"
                sx={{
                  width: "fit-content",
                  ml: { xs: "auto", sm: "unset" },
                }}
              >
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      key={field.value}
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="payment"
                        control={
                          <Radio
                            icon={<CircleOutlinedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                            size="small"
                          />
                        }
                        label={purchasingT("payment")}
                        sx={{ color: "#98A2B3" }}
                      />
                      <FormControlLabel
                        value="event"
                        control={
                          <Radio
                            icon={<CircleOutlinedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                            size="small"
                          />
                        }
                        label={purchasingT("event")}
                        sx={{ color: "#98A2B3" }}
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Stack>

            {watch("type") === "payment" ? (
              <Stack spacing={2}>
                <FormControl
                  fullWidth
                  size="small"
                  error={!!errors.payment_method_id}
                >
                  <InputLabel id="demo-simple-select-label">
                    {t("paymentMethod")}
                  </InputLabel>
                  <Controller
                    name="payment_method_id"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        key={field.value}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={t("paymentMethod")}
                      >
                        {paymentsData?.data
                          ?.filter((pay) => pay.status)
                          .map(({ id, name }) => {
                            return (
                              <MenuItem key={id} value={`${id}`}>
                                {name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    )}
                  />
                  <FormHelperText id="my-helper-text">
                    {errors.payment_method_id?.message}
                  </FormHelperText>
                </FormControl>
                {/* date */}
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      // sx={{ flexBasis: "50%" }}
                      type="date"
                      label={t("paymentDate")}
                      fullWidth
                      size="small"
                      {...field}
                      error={!!errors.date}
                      helperText={errors.date?.message}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Stack>
            ) : (
              <FormControl fullWidth size="small" error={!!errors.eventId}>
                <InputLabel id="demo-simple-select-label">
                  {purchasingT("event")}
                </InputLabel>
                <Controller
                  name="eventId"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      key={field.value}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label={purchasingT("event")}
                    >
                      {supplierEventsList
                        .filter((item: any) => item.remaining > 0)
                        .map(({ id, reference }: any) => {
                          return (
                            <MenuItem key={id} value={`${id}`}>
                              {reference}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  )}
                />
                <FormHelperText id="my-helper-text">
                  {errors.eventId?.message}
                </FormHelperText>
              </FormControl>
            )}

            {/* Amount */}
            <Stack direction={"row"} gap={1} alignItems={"center"}>
              <Controller
                name="amount"
                control={control}
                render={({ field }) => (
                  <TextField
                    type="number"
                    fullWidth
                    label={t("amount")}
                    size="small"
                    {...field}
                    error={!!errors.amount}
                    helperText={errors.amount?.message}
                  />
                )}
              />
              {watch("type") === "event" && (
                <Typography whiteSpace={"nowrap"}>
                  {t("eventCredit")}: {purchaseEventCreditRemaining}
                </Typography>
              )}
            </Stack>
          </Stack>
          {/* files */}
          <Box width={"100%"} mt={2}>
            <FilePondUploader
              onUpload={(e) => setFiles(e)}
              maxFiles={3}
              maxFileSize={3}
            />
          </Box>
          <Stack spacing={2} direction={"row"} mt={3}>
            <Button
              variant="outlined"
              color="tertiary"
              fullWidth
              onClick={onClose}
            >
              {generalT("cancel")}
            </Button>
            <LoadingButton
              variant="contained"
              fullWidth
              type="submit"
              loading={isPending || isPendingEvent}
            >
              {generalT("confirm")}
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </Modal>
  );
};

export default AddPurchasePaymentPopup;
