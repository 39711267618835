import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "../apiClient";

interface IParams {
  start_date?: string;
  end_date?: string;
}

export const useGetCogsSummary = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: [
      "get-inventory-cogs-summary-list",
      params.start_date,
      params.end_date,
    ],
    queryFn: async () => {
      return apiClient.get(`/api/inventory/stock-count-report`, { params });
    },
  });
