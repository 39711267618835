import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import TableHeadText from "./table-head-text";
import moment from "moment";
import TableActions from "./table-actions";
import { useTranslation } from "react-i18next";
import { formatNumber } from "src/utils";

const SalesTable = ({
  rows,
  isLoading,
  refetch,
}: {
  rows: any;
  isLoading: boolean;
  refetch: Function;
}) => {
  const emptyDataArr = rows?.length === 0;
  const { t } = useTranslation("settings");
  const { t: generalT } = useTranslation("general");
  const HeadText = [
    generalT("reference"),
    generalT("branch"),
    generalT("period"),
    generalT("orderQnty"),
    generalT("netSalesWithTax"),
    t("salesMethod"),
    generalT("cogsMethod"),
    t("taxMethod"),
    generalT("cogsTotal"),
    `${generalT("cogs")} %`,
    t("aggregatorSalesWithTax"),
    t("allAggsFees"),
    t("aggDeliveryCostWithTax"),
    t("aggOrderQnty"),
    generalT("tax"),
    t("discount"),
    t("charge"),
    t("tips"),
    generalT("createdAt"),
    generalT("createdBy"),
    generalT("actions"),
  ];

  return (
    <Box overflow={"auto"} maxWidth={{ xs: "100%", md: `calc(100vw - 316px)` }}>
      <TableContainer component={Paper} variant="outlined">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {HeadText.map((item) => (
                <TableCell align="center" sx={{ bgcolor: "#F9FAFB" }}>
                  <TableHeadText text={item} />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableLoadingSkeleton rowsLength={5} cellsLength={21} />
          ) : (
            <TableBody>
              {rows?.map((row: any) => (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center"> {row.reference}</TableCell>
                  <TableCell align="center">{row.branch_name}</TableCell>
                  <TableCell align="center" sx={{ minWidth: "110px" }}>
                    {row.start_date} {row.end_date}
                  </TableCell>
                  <TableCell align="center">{formatNumber(row.quantity)}</TableCell>
                  <TableCell align="center">{formatNumber(row.total_sales)}</TableCell>
                  <TableCell align="center"> {row.sale_method}</TableCell>
                  <TableCell align="center"> {row.cogs_method}</TableCell>
                  <TableCell align="center"> {row.tax_type}</TableCell>
                  <TableCell align="center"> {formatNumber(row.total_cogs.toFixed(2))}</TableCell>
                  <TableCell align="center">%{row.cogs_percentage}</TableCell>
                  <TableCell align="center"> {formatNumber(row.aggregator_sales)}</TableCell>
                  <TableCell align="center">
                    {formatNumber(+Number(row?.aggregator_fees).toFixed(2))}
                  </TableCell>
                  <TableCell align="center">
                    {formatNumber(row.aggregator_delivery_fee)}
                  </TableCell>
                  <TableCell align="center">
                    {formatNumber(row.aggregator_quantity)}
                  </TableCell>
                  <TableCell align="center">{formatNumber(row.tax.toFixed(2))}</TableCell>
                  <TableCell align="center">
                    {formatNumber(row.discount.toFixed(2))}
                  </TableCell>
                  <TableCell align="center">{formatNumber(row.charge)}</TableCell>
                  <TableCell align="center">{formatNumber(row.tips)}</TableCell>
                  <TableCell align="center">
                    {moment(row.created_at, "YYYY-MM-DD HH:mm A").format(
                      "YYYY-MM-DD"
                    )}
                  </TableCell>
                  <TableCell align="center">{row.created_by?.name}</TableCell>
                  <TableCell align="center">
                    <TableActions
                      rowId={row.id}
                      rowName={row.reference}
                      refetch={refetch}
                      type={row.sale_method}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {emptyDataArr && <NoData />}
      </TableContainer>
    </Box>
  );
};
export default SalesTable;
