import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import settingIcon from "src/assets/svg-icons/side-bar/settings.svg";
import RightArrow from "src/assets/svg-icons/aggregators/right_arrow.svg";
import * as yup from "yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InfoBox from "src/shared/components/info-box";
import { LoadingButton } from "@mui/lab";
import { useGetBranches, useGetJourneys } from "src/api/generic";
import {
  useAddCogsMutation,
  useGetCogsById,
  useUpdateCogsMutation,
} from "src/api/settings/cogs";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { FormModeTypes } from "src/types/generic";
import { useTranslation } from "react-i18next";
import { useGetTotalPurchaseByDateRangeMutation } from "src/api/purchasing/purchasing";
import moment from "moment";
import FilePondUploader from "src/shared/components/file-pond-uploader";
import { formatNumber } from "src/utils";
import { useGetCogsStockCount } from "src/api/inventory/counts";

const schema = yup.object().shape({
  branch_id: yup.number().required("Required"),
  journeys: yup
    .array()
    .of(yup.number().required("Required"))
    .required("Required")
    .min(1, "Required"),
  beginning_total_inventory: yup.string().required("Required"),
  total_purchase: yup.string().required("Required"),
  ending_total_inventory: yup.string().required("Required"),
});

interface FormInputs extends yup.InferType<typeof schema> {}

const AddNewCogs = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation("settings");
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const { t: generalT } = useTranslation("general");
  const mode: FormModeTypes = !id ? "add" : "edit";
  const [files, setFiles] = useState<any>([]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      journeys: [],
    },
  });

  // APIS
  const { data: dataBranches } = useGetBranches();
  const { data: journeysData } = useGetJourneys({
    enabled: !!watch("branch_id"),
    branches: [watch("branch_id")],
  });
  const { mutate, status, error, isPending } = useAddCogsMutation();
  const {
    mutate: mutateUpdate,
    error: errorUpdate,
    status: statusUpdate,
    isPending: isPendingUpdate,
  } = useUpdateCogsMutation();
  const {
    data,
    status: getCogsByIdStatus,
    refetch,
  } = useGetCogsById({
    enabled: !!id,
    id: id as string,
  });
  const { mutate: mutateGetTotalPurchase, data: totalPurchaseData } =
    useGetTotalPurchaseByDateRangeMutation();

  const navigateToInventory = () => {
    navigate("/inventory/cogs");
  };

  const selectedJourneys = watch("journeys").map((item) => {
    const journey = journeysData?.data.journeys?.find(
      (journey: any) => journey.id === item
    );
    return journey;
  });

  let earliestStartDate: string | undefined = undefined;
  let latestEndDate: string | undefined = undefined;

  if (selectedJourneys.length > 0) {
    earliestStartDate = selectedJourneys[0]?.start_date;
    latestEndDate = selectedJourneys[0]?.end_date;
    selectedJourneys.forEach((journey) => {
      const startDate = journey?.start_date;
      const endDate = journey?.end_date;

      // Update earliest start date if the current journey's start date is earlier
      if (moment(startDate).isBefore(moment(earliestStartDate))) {
        earliestStartDate = startDate;
      }

      // Update latest end date if the current journey's end date is later
      if (moment(endDate).isAfter(moment(latestEndDate))) {
        latestEndDate = endDate;
      }
    });
  }

  const { data: cogsStockCountsData } = useGetCogsStockCount({
    start_date:
      moment(earliestStartDate).subtract(1, "days").format("YYYY-MM-DD") || "",
    end_date: latestEndDate || "",
  });

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (CogsTotal < 0) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: "Total inventory can not be on Minus ! ",
        },
      ]);
      return;
    }
    const dataToSend: any = {
      branch_id: data.branch_id,
      first_count_tax:
        mode === "add"
          ? cogsStockCountsData?.data[0]?.total
          : +data.beginning_total_inventory,
      first_purchase_tax:
        mode === "add" ? totalPurchaseData?.total : +data.total_purchase,
      end_count_tax:
        mode === "add"
          ? cogsStockCountsData?.data[1]?.total
          : +data.ending_total_inventory,
      first_stock_count_id: cogsStockCountsData?.data?.[0]?.id,
      last_stock_count_id: cogsStockCountsData?.data?.[1]?.id,
    };
    const formData = new FormData();
    // append data
    for (const key in dataToSend) {
      formData.append(key, dataToSend[key]);
    }
    //append journeys
    data.journeys.forEach((journeyId: any, index: number) => {
      formData.append(`journeys[${index}]`, journeyId);
    });
    // append files
    if (files.length > 0) {
      files.forEach((file: any, index: number) => {
        formData.append(`files[${index}]`, file);
      });
    }
    if (mode === "add") {
      mutate(formData);
    } else {
      mutateUpdate({
        id: Number(id),
        journeys: data.journeys,
        ...dataToSend,
      });
    }
  };

  useEffect(() => {
    if (watch("branch_id") && earliestStartDate && latestEndDate) {
      mutateGetTotalPurchase({
        branch_id: watch("branch_id"),
        start_date: earliestStartDate || "",
        end_date: latestEndDate || "",
      });
    }
  }, [watch("branch_id"), watch("journeys")]);

  useEffect(() => {
    setValue("total_purchase", totalPurchaseData?.total.toFixed(2));
  }, [totalPurchaseData]);

  useEffect(() => {
    if (cogsStockCountsData?.data.length > 0) {
      setValue(
        "beginning_total_inventory",
        cogsStockCountsData?.data[0]?.total
      );
      setValue("ending_total_inventory", cogsStockCountsData?.data[1]?.total);
    }
  }, [cogsStockCountsData]);

  useEffect(() => {
    if (getCogsByIdStatus === "success") {
      reset({
        branch_id: data.data.branch.id,
        journeys: data.data.journeys,
        beginning_total_inventory: data.data.first_count_tax,
        total_purchase: data.data.first_purchase_tax,
        ending_total_inventory: data.data.end_count_tax,
      });
    }
  }, [getCogsByIdStatus]);

  //Add agg cost/fee success and error handling
  useEffect(() => {
    if (status === "success") {
      navigateToInventory();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("addedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors?.[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  //Update agg cost/fee success and error handling
  useEffect(() => {
    if (statusUpdate === "success") {
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("updatedSuccessfully"),
        },
      ]);
    } else if (statusUpdate === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            errorUpdate?.data.errors?.[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [statusUpdate]);

  const CogsTotal =
    +watch("beginning_total_inventory") +
    +watch("total_purchase") -
    +watch("ending_total_inventory");

  return (
    <Stack gap={3} p={{ xs: 2, sm: 4 }}>
      <Stack
        direction={"row"}
        spacing={"12px"}
        alignItems={"center"}
        color={"#101828"}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"60px"}
          height={"60px"}
          bgcolor={"#D0D5DD"}
          borderRadius={"16px"}
        >
          <img src={settingIcon} width={"32px"} alt="" />
        </Box>
        <Typography fontSize={"32px"} lineHeight={"38.4px"}>
          {generalT("inventory")}
        </Typography>
      </Stack>
      {/* divider */}
      <Box bgcolor={"#F2F4F7"} width={"100%"} height={"1px"} />

      <Stack spacing={2} direction={"row"} mb={3} alignItems={"center"}>
        <Typography
          color={"#475467"}
          lineHeight={"19.2px"}
          onClick={navigateToInventory}
          sx={{ cursor: "pointer" }}
        >
          {generalT("inventory")}
        </Typography>
        <img src={RightArrow} alt="" />
        <Typography
          color={"#475467"}
          lineHeight={"19.2px"}
          onClick={navigateToInventory}
          sx={{ cursor: "pointer" }}
        >
          {generalT("cogs")}
        </Typography>
        <img src={RightArrow} alt="" />
        <Box borderRadius={"4px"} bgcolor={"#EAECF0"} p={"4px 8px"}>
          <Typography color={"#344054"} fontWeight={600} lineHeight={"19.2px"}>
            {t("addNewCogs")}
          </Typography>
        </Box>
      </Stack>
      {/* branch */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} width={664}>
          <FormControl
            fullWidth
            size="small"
            error={!!errors.branch_id}
            disabled={mode === "edit"}
          >
            <InputLabel id="demo-simple-select-label">
              {generalT("branchLocation")}
            </InputLabel>
            <Controller
              name="branch_id"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  key={field.value}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={generalT("branchLocation")}
                >
                  {dataBranches?.data?.map(({ id, name }) => {
                    return (
                      <MenuItem key={id} value={`${id}`}>
                        {name}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            />
            <FormHelperText id="my-helper-text">
              {errors.branch_id?.message}
            </FormHelperText>
          </FormControl>
          {/* journeys */}
          <FormControl
            disabled={mode === "edit"}
            fullWidth
            size="small"
            error={!!errors.journeys}
          >
            <InputLabel id="demo-multiple-checkbox-label">
              {generalT("selectJourneys")}
            </InputLabel>
            <Controller
              name={`journeys`}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  input={<OutlinedInput label={generalT("selectJourneys")} />}
                  renderValue={(selected) =>
                    selected
                      .map((selectedId) => {
                        const journey = journeysData?.data.journeys?.find(
                          (journey: any) => journey.id === +selectedId
                        );
                        return `${journey?.start_date} ${generalT("to")} ${
                          journey?.end_date
                        }`;
                      })
                      .join(", ")
                  }
                >
                  {/* If no branch selected  */}
                  {!watch("branch_id") ? (
                    <Typography
                      color={"textSecondary"}
                      align="center"
                      sx={{ pointerEvents: "none" }}
                    >
                      {t("selectBranchFirst")}
                    </Typography>
                  ) : null}
                  {/* branches list */}
                  {journeysData?.data.journeys
                    .filter((jou: any) => jou.cogs?.type !== "formula")
                    .map((journey: any) => (
                      <MenuItem key={journey.id} value={journey.id}>
                        <Checkbox
                          checked={
                            field.value && field.value.indexOf(journey.id) > -1
                          }
                        />
                        {journey?.start_date} {generalT("to")}{" "}
                        {journey?.end_date}
                      </MenuItem>
                    ))}
                  {/* No journeys available*/}
                  {!!watch("branch_id") &&
                  journeysData?.data.journeys.filter(
                    (jou: any) => jou.cogs?.type !== "formula"
                  ).length === 0 ? (
                    <Typography
                      color={"textSecondary"}
                      align="center"
                      sx={{ pointerEvents: "none" }}
                    >
                      {t("noJourneysAvailable")}
                    </Typography>
                  ) : null}
                </Select>
              )}
            />
            <FormHelperText id="my-helper-text">
              {errors.journeys?.message}
            </FormHelperText>
          </FormControl>

          {/* divider */}
          <Box bgcolor={"#EAECF0"} height={"1px"} width={"100%"} />
          <InfoBox text={t("beginningInvShould")} variant="warning" />
          <Controller
            name="beginning_total_inventory"
            control={control}
            render={({ field }) => (
              <TextField
                label={t("addBeginningTotalInv")}
                fullWidth
                size="small"
                {...field}
                value={`${cogsStockCountsData?.data?.[0]?.reference || ""} ${
                  field.value ?? ""
                }`}
                error={!!errors.beginning_total_inventory}
                helperText={errors.beginning_total_inventory?.message}
                InputLabelProps={{
                  shrink: field.value !== undefined ? true : false,
                }}
                disabled
              />
            )}
          />
          <Controller
            name="total_purchase"
            control={control}
            render={({ field }) => (
              <TextField
                label={t("totalPurchaseExcluding")}
                fullWidth
                size="small"
                {...field}
                error={!!errors.total_purchase}
                helperText={errors.total_purchase?.message}
                InputLabelProps={{
                  shrink: field.value !== undefined ? true : false,
                }}
                disabled
                sx={{ bgcolor: "#f8f8f8" }}
              />
            )}
          />
          <InfoBox text={t("endingInvShould")} variant="warning" />
          <Controller
            name="ending_total_inventory"
            control={control}
            render={({ field }) => (
              <TextField
                label={t("addEndingTotalInv")}
                fullWidth
                size="small"
                {...field}
                value={`${cogsStockCountsData?.data?.[1]?.reference || ""} ${
                  field.value ?? ""
                }`}
                error={!!errors.ending_total_inventory}
                helperText={errors.ending_total_inventory?.message}
                InputLabelProps={{
                  shrink: field.value !== undefined ? true : false,
                }}
                disabled
              />
            )}
          />

          <Stack gap={1.5} alignItems={"flex-start"}>
            <Box width={"100%"}>
              <Box height={"7px"} bgcolor={"#D0D5DD"} />
              <Stack p={2} gap={1.5} bgcolor={"#F9FAFB"}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography fontSize={"14px"} color={"#344054"}>
                    {t("beginningTotalInv")}
                  </Typography>
                  <Typography fontSize={"14px"} color={"#344054"}>
                    {watch("beginning_total_inventory")
                      ? formatNumber(
                          +Number(watch("beginning_total_inventory")).toFixed(2)
                        )
                      : "--"}{" "}
                    {generalT("sar")}
                  </Typography>
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography fontSize={"14px"} color={"#344054"}>
                    {t("totalPurchase")}
                  </Typography>
                  <Typography fontSize={"14px"} color={"#344054"}>
                    {+watch("total_purchase") >= 0
                      ? formatNumber(
                          +Number(watch("total_purchase")).toFixed(2)
                        )
                      : "--"}{" "}
                    {generalT("sar")}
                  </Typography>
                </Stack>

                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography fontSize={"14px"} color={"#344054"}>
                    {t("endingTotalInv")}
                  </Typography>
                  <Typography fontSize={"14px"} color={"#344054"}>
                    {watch("ending_total_inventory")
                      ? formatNumber(
                          +Number(watch("ending_total_inventory")).toFixed(2)
                        )
                      : "--"}{" "}
                    {generalT("sar")}
                  </Typography>
                </Stack>

                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography fontSize={"14px"} color={"#344054"}>
                    {t("cogsTotal")}
                  </Typography>
                  <Typography fontSize={"14px"} color={"#344054"}>
                    {CogsTotal >= 0
                      ? formatNumber(+CogsTotal.toFixed(2))
                      : "--"}{" "}
                    {generalT("sar")}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Stack>

          {/* attachments */}
          {mode === "add" && (
            <Box width={"100%"} mt={2}>
              <FilePondUploader
                onUpload={(e) => setFiles(e)}
                maxFiles={3}
                maxFileSize={3}
                acceptedFileTypes={[
                  "image/*",
                  "application/pdf",
                  "text/csv",
                  "application/vnd.ms-excel",
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                ]}
              />
            </Box>
          )}

          <Stack direction={"row"} pt={1} justifyContent={"space-between"}>
            <Button
              variant="outlined"
              color="tertiary"
              onClick={navigateToInventory}
            >
              {generalT("back")}
            </Button>
            {mode === "add" ? (
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isPending || isPendingUpdate}
              >
                {generalT("save")}
              </LoadingButton>
            ) : (
              <></>
            )}
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};

export default AddNewCogs;
