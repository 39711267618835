import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "../apiClient";

interface IParams {
  page?: number;
  status?: string;
}

interface IStockCountBody {
  id?: number;
}

export const useGetStockCountsList = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-stock-counts-list", params.page, params.status || ""],
    queryFn: async () => {
      return apiClient.get(`/api/stockCounts`, { params });
    },
  });

export const useAddStockCountMutation = () =>
  useMutation<any, APIErrorResponse, any>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/stockCounts`, data);
    },
  });

export const useUpdateStockCountMutation = () =>
  useMutation<void, APIErrorResponse, any>({
    mutationFn: async (data) => {
      const { id, ...rest } = data;
      return apiClient.put(`/api/stockCounts/${id}`, rest);
    },
  });

export const useDeleteStockCountMutation = () =>
  useMutation<void, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.delete(`/api/stockCounts/${id}`);
    },
  });

export const useGetCountById = ({
  id,
  enabled,
}: {
  id: string;
  enabled: boolean;
}) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-stock-count-by-id", id],
    queryFn: async () => {
      return apiClient.get(`/api/stockCounts/${id}`);
    },
    gcTime: 0,
    enabled,
  });

export const useImportCountMutation = () =>
  useMutation<any, APIErrorResponse, FormData>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/stockCounts/import`, data);
    },
  });

export const useGetCogsStockCount = (params: {
  start_date: string;
  end_date: string;
}) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: [
      "get-cogs-stock-counts-list",
      params.start_date,
      params.end_date,
    ],
    queryFn: async () => {
      return apiClient.get(`/api/stockCounts/get-cogs-stock-count`, { params });
    },
    enabled: !!params.start_date && !!params.end_date,
    gcTime: 0
  });

export const useDownloadStockCountDetails = (params: {
  stockCountId: number;
}) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-account-3rd-eye-report", params.stockCountId],
    queryFn: async () => {
      return apiClient.get(
        `/api/stockCounts/download?stock_count_id=${params.stockCountId}`
      );
    },
    enabled: false,
    gcTime: 0,
  });
