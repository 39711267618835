import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DeleteIcon from "src/assets/svg-icons/aggregators/delete.svg";
import AddMoreIcon from "src/assets/svg-icons/settings/add_more.svg";
import {
  useAddUserMutation,
  useUpdateUserMutation,
} from "src/api/settings/users";
import { useGetRoles } from "src/api/settings/roles";
import { useGetBranches } from "src/api/generic";
import { LoadingButton } from "@mui/lab";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
  mode: yup
    .string()
    .required("Required")
    .oneOf(["add", "edit"] as const),
  name: yup.string().required("Required"),
  email: yup.string().required("Required"),
  password: yup.string().when("mode", {
    is: "add",
    then: (value) => value.required("Required"),
  }),
  branchesRoles: yup
    .array()
    .of(
      yup.object().shape({
        branchId: yup.string().required("Required"),
        roles: yup
          .array()
          .of(yup.string().required("Required"))
          .required("Required")
          .min(1, "Required"),
      })
    )
    .required(""),
  status: yup
    .string()
    .required("")
    .oneOf(["yes", "no"] as const),
});

const initForm: FormInputs = {
  mode: "add",
  name: "",
  branchesRoles: [
    {
      branchId: "",
      roles: [],
    },
  ],
  email: "",
  password: "",
  status: "yes",
};

interface FormInputs extends yup.InferType<typeof schema> {}

interface IProps {
  open: boolean;
  handleClose: () => void;
  refetch: Function;
  userData?: any;
}

const AddEditUser = (props: IProps) => {
  const { open, handleClose, refetch, userData } = props;
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const { t, i18n } = useTranslation("settings");
  const { t: generalT } = useTranslation("general");

  //React hook form
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: initForm,
  });
  const { fields, append, remove } = useFieldArray({
    name: "branchesRoles",
    control,
  });

  const mode = getValues("mode");

  // APIS
  const { mutate, isPending, status, error } = useAddUserMutation();
  const {
    mutate: mutateUpdate,
    isPending: isPendingUpdate,
    status: statusUpdate,
    error: errorUpdate,
  } = useUpdateUserMutation();
  const { data: rolesData } = useGetRoles();
  const { data: branchesData } = useGetBranches();

  const handleAppendRole = () => {
    append({
      branchId: "",
      roles: [],
    });
  };

  const handleRemoveRole = (index: number) => {
    remove(index);
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const dataToSend = {
      name: data.name,
      email: data.email,
      password: data.password,
      branches: data.branchesRoles.map((branchRoles) => {
        return {
          id: +branchRoles.branchId,
          roles: branchRoles.roles.map((roleId) => +roleId),
        };
      }),
      status: data.status === "yes" ? true : false,
    };
    if (mode === "add") {
      mutate(dataToSend);
    } else {
      mutateUpdate({
        id: userData.id,
        ...dataToSend,
      });
    }
  };

  useEffect(() => {
    if (userData) {
      const userBranchesRoles = userData?.branches.map((branch: any) => {
        return {
          branchId: branch.id,
          roles: branch.roles.map((roleId: any) => `${roleId}`),
        };
      });
      reset({
        name: userData.name,
        email: userData.email,
        branchesRoles: userBranchesRoles,
        mode: "edit",
        status: userData.status ? "yes" : "no",
      });
    }
  }, [userData]);

  //Add user success and error handling
  useEffect(() => {
    if (status === "success") {
      reset(initForm);
      handleClose();
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("addedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  //Update user success and error handling
  useEffect(() => {
    if (statusUpdate === "success") {
      reset(initForm);
      handleClose();
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("updatedSuccessfully"),
        },
      ]);
    } else if (statusUpdate === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            errorUpdate?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [statusUpdate]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing={3}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { sx: "100%", lg: "537px" },
          bgcolor: "#FFF",
          border: "1px solid #D0D5DD",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={0}
        >
          <Typography color={"#101828"} fontSize={"20px"} lineHeight={"24px"}>
            {mode === "add" ? t("addNewUser") : t("editUser")}
          </Typography>
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t("userName")}
                  fullWidth
                  size="small"
                  {...field}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  label={generalT("email")}
                  fullWidth
                  size="small"
                  {...field}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  label={generalT("password")}
                  fullWidth
                  size="small"
                  {...field}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                />
              )}
            />
            <Stack spacing={"9px"}>
              <Typography color={"#667085"} fontSize={"13px"}>
                {t("branchesAndRoles")}
              </Typography>
              {fields.map((branchRoleField, index) => {
                return (
                  <Box key={branchRoleField.id}>
                    <Stack
                      direction={"row"}
                      spacing={"10px"}
                      alignItems={"flex-start"}
                    >
                      <FormControl
                        fullWidth
                        size="small"
                        error={
                          errors.branchesRoles &&
                          !!errors.branchesRoles[index]?.branchId
                        }
                      >
                        <InputLabel id="demo-simple-select-label">
                          {generalT("branch")}
                        </InputLabel>
                        <Controller
                          name={`branchesRoles.${index}.branchId`}
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              key={field.value}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label={generalT("branch")}
                            >
                              {branchesData?.data?.map(({ id, name }) => {
                                return (
                                  <MenuItem key={id} value={`${id}`}>
                                    {name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          )}
                        />
                        <FormHelperText id="my-helper-text">
                          {errors.branchesRoles &&
                            errors.branchesRoles[index]?.branchId?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl
                        fullWidth
                        size="small"
                        error={
                          errors.branchesRoles &&
                          !!errors.branchesRoles[index]?.roles
                        }
                      >
                        <InputLabel id="demo-multiple-checkbox-label">
                          {t("selectRoles")}
                        </InputLabel>
                        <Controller
                          name={`branchesRoles.${index}.roles`}
                          control={control}
                          defaultValue={[]}
                          render={({ field }) => (
                            <Select
                              {...field}
                              labelId="demo-multiple-checkbox-label"
                              id="demo-multiple-checkbox"
                              multiple
                              input={<OutlinedInput label={t("selectRoles")} />}
                              renderValue={(selected) =>
                                selected
                                  .map(
                                    (selectedId: string) =>
                                      rolesData?.data.find(
                                        (role: any) => role.id === +selectedId
                                      )?.name
                                  )
                                  .join(", ")
                              }
                            >
                              {rolesData?.data.map(
                                (role: any) =>
                                  !role.is_default && (
                                    <MenuItem
                                      key={role.id}
                                      value={`${role.id}`}
                                    >
                                      <Checkbox
                                        checked={
                                          field.value &&
                                          field.value.indexOf(`${role.id}`) > -1
                                        }
                                      />
                                      {role.name}
                                    </MenuItem>
                                  )
                              )}
                            </Select>
                          )}
                        />
                        <FormHelperText id="my-helper-text">
                          {errors.branchesRoles &&
                            errors.branchesRoles[index]?.roles?.message}
                        </FormHelperText>
                      </FormControl>
                      {fields.length > 1 && (
                        <IconButton onClick={() => handleRemoveRole(index)}>
                          <img src={DeleteIcon} alt="" />
                        </IconButton>
                      )}
                    </Stack>
                  </Box>
                );
              })}

              <Button
                variant="text"
                size="small"
                startIcon={<img src={AddMoreIcon} alt="" />}
                onClick={handleAppendRole}
                sx={{ alignSelf: "flex-start" }}
              >
                {t("addMoreRole")}
              </Button>
            </Stack>

            <Stack
              direction={"row"}
              alignItems={"center"}
              gap={{ xs: 1, sm: 1, md: 3 }}
              flexWrap={"wrap"}
              width={"100%"}
            >
              <Typography color={"textSecondary"}>
                {t("isUserActive")}
                {i18n.language === "en" ? "?" : "؟"}
              </Typography>
              <FormControl
                fullWidth
                size="small"
                error={!!errors.status}
                sx={{ width: "fit-content", ml: { xs: "auto", sm: "unset" } }}
                disabled={userData?.type === "owner"}
              >
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      key={field.value}
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="yes"
                        control={
                          <Radio
                            icon={<CircleOutlinedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                          />
                        }
                        label={generalT("yes")}
                        sx={{ color: "#98A2B3" }}
                      />
                      <FormControlLabel
                        value="no"
                        control={
                          <Radio
                            icon={<CircleOutlinedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                          />
                        }
                        label={generalT("no")}
                        sx={{ color: "#98A2B3" }}
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Stack>
            <FormHelperText error id="my-helper-text" sx={{ mt: -3, px: 2 }}>
              {errors.status?.message}
            </FormHelperText>
          </Stack>
          <Stack spacing={2} direction={"row"} mt={3}>
            <Button
              variant="outlined"
              color="tertiary"
              fullWidth
              onClick={handleClose}
            >
              {generalT("cancel")}
            </Button>
            <LoadingButton
              variant="contained"
              fullWidth
              type="submit"
              loading={isPending || isPendingUpdate}
            >
              {mode === "add" ? t("addUser") : generalT("save")}
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </Modal>
  );
};

export default AddEditUser;
