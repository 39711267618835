import { Box, Button, Stack, TablePagination, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import AuditingTable from "./table";
import MatchingIcon from "src/assets/svg-icons/side-bar/matching.svg";
import { useGetAuditingList } from "src/api/auditing/auditing";
import { useEffect, useState } from "react";
import FilterDrawer from "./filters";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import FilterIcon from "src/assets/svg-icons/aggregators/filter.svg";

const Auditing = () => {
  const { t } = useTranslation("auditing");
  const { t: generalT } = useTranslation("general");
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [openFilter, setOpenFilter] = useState(false);
  const [refNum, setRefNum] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");
  const [dueDate, setDueDate] = useState("");
  const [type, setType] = useState("all");

  const filters = {
    ...(!!refNum && { reference_number: refNum }),
    ...(!!type && type !== "all" && { type: type }),
    ...(!!filterStatus && filterStatus !== "all" && { status: filterStatus }),
    ...(!!dueDate && { date: dueDate }),
  };

  const { data, isFetching, refetch } = useGetAuditingList({
    page: pageNumber + 1,
    ...filters,
  });

  const handleOpenFilter = () => setOpenFilter(true);
  const handleCloseFilter = () => setOpenFilter(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  useEffect(() => {
    if (!openFilter) {
      setPageNumber(0);
      refetch();
    }
  }, [openFilter]);

  useEffect(() => {
    if (!!data) {
      setTotal(data.meta.total);
    }
  }, [data]);

  return (
    <Stack gap={3} p={{ xs: 2, sm: "8px 32px 32px 32px" }}>
      <Stack
        direction={"row"}
        spacing={"12px"}
        alignItems={"center"}
        color={"#101828"}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"60px"}
          height={"60px"}
          bgcolor={"#D0D5DD"}
          borderRadius={"16px"}
        >
          <img src={MatchingIcon} width={"32px"} alt="" />
        </Box>
        <Typography fontSize={"32px"} lineHeight={"38.4px"}>
          {t("auditing")}
        </Typography>
      </Stack>
      {/* divider */}
      <Box bgcolor={"#F2F4F7"} width={"100%"} height={"1px"} />
      <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
        <Button
          color="tertiary"
          variant="outlined"
          startIcon={<img src={FilterIcon} alt="" />}
          onClick={handleOpenFilter}
        >
          {generalT("filter")}
        </Button>
        <LoadingButton
          sx={{
            border: "1px solid #98A2B3",
            height: "40px",
            fontWeight: 500,
            borderRadius: "4px",
            padding: "7px 10px",
            background: "#FCFCFD",
            fontSize: "16px",
          }}
          color="tertiary"
          variant="outlined"
          endIcon={<img src={DownloadIcon} alt="" />}
        >
          {generalT("download")}
        </LoadingButton>
      </Stack>
      <AuditingTable
        rows={data?.data}
        isLoading={isFetching}
        refetch={refetch}
      />
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        count={total}
        rowsPerPage={20}
        page={pageNumber}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />

      <FilterDrawer
        open={openFilter}
        refNum={refNum}
        setRefNum={setRefNum}
        status={filterStatus}
        setStatus={setFilterStatus}
        dueDate={dueDate}
        setDueDate={setDueDate}
        handleClose={handleCloseFilter}
        type={type}
        setType={setType}
      />
    </Stack>
  );
};

export default Auditing;
