import { IconButton, Stack } from "@mui/material";
import DeleteIcon from "src/assets/svg-icons/aggregators/delete.svg";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { ConfirmPopup } from "src/shared/components/confirm-popup";
import { useDeletePurchaseEventPaymentMutation } from "src/api/purchasing/purchasing";

const TableActions = ({
  rowId,
  rowRef,
  refetch,
}: {
  rowId: number;
  refetch: Function;
  rowRef: string;
}) => {
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [open, setOpen] = useState(false);

  const { mutate, isPending, status, error } =
    useDeletePurchaseEventPaymentMutation();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDelete = () => {
    mutate(rowId);
  };

  useEffect(() => {
    if (status === "success") {
      refetch();
      handleClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: "Deleted successfully",
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || "Oops! Something went wrong.",
        },
      ]);
    }
  }, [status]);

  return (
    <Stack
      direction={"row"}
      spacing={"12px"}
      width={"100%"}
      justifyContent={"center"}
    >
      <IconButton onClick={handleOpen}>
        <img src={DeleteIcon} alt="" />
      </IconButton>

      <ConfirmPopup
        open={open}
        item={rowRef}
        handleClose={handleClose}
        handleConfirm={handleDelete}
        isPending={isPending}
      />
    </Stack>
  );
};
export default TableActions;
