import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableActions from "./table-actions";
import { NoData } from "src/shared/components/tables/no-data";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import AmountType from "./amount-type";

const EventTable = ({
  data,
  isFetching,
  refetch,
}: {
  data: any;
  isFetching: boolean;
  refetch: Function;
}) => {
  const emptyDataArr = data?.length === 0;
  const { t } = useTranslation("purchasing");

  const HeadText = [
    t("reference"),
    t("eventType"),
    t("branch"),
    t("from"),
    t("to"),
    t("date"),
    t("total"),
    t("used"),
    t("invoiceRef"),
    t("note"),
    t("by"),
    t("createdAt"),
    t("actions"),
  ];

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {HeadText.map((item) => (
              <TableCell
                align={"center"}
                sx={{
                  bgcolor: "#F9FAFB",
                }}
              >
                <Typography
                  fontSize={"12px"}
                  color={"#475467"}
                  lineHeight={"18px"}
                  fontWeight={500}
                >
                  {item}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {isFetching ? (
          <TableLoadingSkeleton rowsLength={15} cellsLength={13} />
        ) : (
          <TableBody>
            {data?.map((row: any) => (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                key={row.id}
              >
                <TableCell align="center">{row.reference}</TableCell>
                <TableCell align="center">{row.type}</TableCell>
                <TableCell align="center">{row.branch?.name}</TableCell>
                <TableCell align="center">{row.from}</TableCell>
                <TableCell align="center">{row.to}</TableCell>
                <TableCell align="center">{row.date}</TableCell>
                <TableCell align="center">{row.amount}</TableCell>
                <TableCell align="center">{row.used}</TableCell>
                <TableCell align="center">{row.purchases} {row.invoices}</TableCell>
                <TableCell align="center">{row.note}</TableCell>
                <TableCell align="center">{row.created_by?.name}</TableCell>
                <TableCell align="center">{row.created_at}</TableCell>
                <TableCell align="right">
                  <TableActions row={row} refetch={refetch} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      {emptyDataArr && <NoData />}
    </TableContainer>
  );
};
export default EventTable;
