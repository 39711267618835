import { Stack, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const InfoBox = ({
  text,
  variant = "warning",
}: {
  text: string;
  variant?: "warning" | "error" | "info";
}) => {
  return (
    <Stack
      width={"100%"}
      bgcolor={
        variant === "error"
          ? "#FEE4E2"
          : variant === "info"
          ? "#F2F2FD"
          : "#FFF3EA"
      }
      borderRadius={"4px"}
      padding={1}
      direction={"row"}
      gap={"10px"}
    >
      <InfoOutlinedIcon
        color={
          variant === "error"
            ? "error"
            : variant === "info"
            ? "info"
            : "primary"
        }
      />
      <Typography color={variant === "error" ? "error" : "black"}>
        {text}
      </Typography>
    </Stack>
  );
};

export default InfoBox;
