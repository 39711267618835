import { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useGetBranches, useGetPaymentMethods } from "src/api/generic";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { LoadingButton } from "@mui/lab";
import FilePondUploader from "src/shared/components/file-pond-uploader";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useTranslation } from "react-i18next";
import { useGetSuppliersList } from "src/api/purchasing/suppliers";
import { useGetSupplierItems } from "src/api/purchasing/purchasing";
import DeleteIcon from "src/assets/svg-icons/aggregators/delete.svg";
import AddMoreIcon from "src/assets/svg-icons/settings/add_more.svg";
import { useGetItemsList } from "src/api/purchasing/items";
import InfoBox from "src/shared/components/info-box";
import { ConfirmPopup } from "src/shared/components/confirm-popup";
import { useUpdateAuditMutation } from "src/api/auditing/auditing";
import FileItem from "src/shared/components/file-item";
import { downloadFileFromUrl, formatBytes } from "src/utils";

const schema = yup.object().shape({
  branch_id: yup.number().required("Required"),
  supplier: yup
    .object()
    .shape({
      id: yup.number().required("Required"),
    })
    .required("Required"),
  amount: yup.string().required("Required"),
  include_tax: yup
    .number()
    .required("Required")
    .oneOf([1, 0] as const),

  tax: yup.string().required("Required"),
  invoice_number: yup.string(),
  has_issue: yup.boolean().required("Required"),
  note: yup.string().when("has_issue", {
    is: (value: boolean) => value === true,
    then: (value) => value.required("Required"),
  }),
  invoice_date: yup.string().required("Required"),
  date: yup.string().required("Required"),
  payments: yup
    .array()
    .of(
      yup.object().shape({
        payment_method_id: yup
          .string()
          .test(
            "payment-method-required",
            "Payment method is required when amount is specified",
            function (value) {
              const { amount } = this.parent; // Access payment_amount in the same object
              if (amount && !value) {
                return false; // Fail if amount exists but payment method is missing
              }
              return true;
            }
          ),
        amount: yup
          .string()
          .test(
            "payment-amount-required",
            "Payment amount is required when payment method is selected",
            function (value) {
              const { payment_method_id } = this.parent; // Access payment_method_id in the same object
              if (payment_method_id && !value) {
                return false; // Fail if payment method exists but amount is missing
              }
              return true;
            }
          ),
      })
    )
    .required("Required"),
  //items
  items: yup
    .array()
    .of(
      yup.object().shape({
        // item_id: yup.string().required("Required"),
        item: yup
          .object()
          .shape({
            id: yup.string().required("required"),
            sku: yup.string(),
            name: yup.string(),
            unit: yup.object().shape({
              name: yup.string(),
            }),
          })
          .required("Required"),
        quantity: yup.string().required("Required"),
        amount: yup.string().required("Required"),
      })
    )
    .required("Required"),
  items_include_tax: yup
    .number()
    .required("Required")
    .oneOf([1, 0] as const),
});

const steps = [
  {
    name: "purchaseDetails",
    fields: [
      "branch_id",
      "supplier",
      "amount",
      "include_tax",
      "tax",
      "invoice_number",
      "note",
      "invoice_date",
      "date",
      "payments",
    ],
  },
  {
    name: "purchaseItems",
    fields: ["items"],
  },
];

export interface FormInputs extends yup.InferType<typeof schema> {}

interface IProps {
  open: boolean;
  handleClose: () => void;
  row?: any;
  refetch: Function;
}

const AddPurchasePopup = (props: IProps) => {
  const { open, handleClose, row, refetch } = props;
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [files, setFiles] = useState<any>([]);
  const { t } = useTranslation("purchasing");
  const { t: generalT } = useTranslation("general");
  const { t: auditingT } = useTranslation("auditing");
  const mode =
    row.status === "confirmed" || row.status === "issue" ? "edit" : "add";
  const [step, setStep] = useState(0);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isResetting, setIsResetting] = useState(false);

  //RHF
  const {
    control,
    formState: { errors },
    watch,
    getValues,
    reset,
    setValue,
    trigger,
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      tax: "0",
      payments: [
        {
          payment_method_id: "",
          amount: "",
        },
      ],
      has_issue: false,
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "payments",
    control,
  });

  const {
    fields: itemFields,
    append: appendItem,
    remove: removeItem,
  } = useFieldArray({
    name: "items",
    control,
  });

  //APIS
  const { data: branchesData } = useGetBranches();
  const { data: suppliersData } = useGetSuppliersList({});
  const { data: paymentsData } = useGetPaymentMethods({
    enabled: !!watch("branch_id"),
    branches: [watch("branch_id")],
  });
  const {
    mutate: mutateUpdate,
    status: statusUpdate,
    error: errorUpdate,
    isPending: isPendingUpdate,
  } = useUpdateAuditMutation();

  const { data: itemsData, isLoading: isLoadingItems } = useGetItemsList();
  const { data: supplierRelatedItemsData } = useGetSupplierItems({
    enabled: !!watch("supplier.id"),
    supplier_id: `${watch("supplier.id")}`,
  });

  const branchHasItemsFeature = branchesData?.data.find((branch) => {
    return branch.id === +watch("branch_id");
  })?.active_subscriptions?.package.has_items;

  let totalItemsAmount = 0;

  watch("items")?.forEach(
    (item) =>
      (totalItemsAmount +=
        +watch("items_include_tax") === 1 ? +item.amount / 1.15 : +item.amount)
  );

  const amountWithoutTax =
    +watch("include_tax") === 1
      ? +(+watch("amount") - +watch("tax")).toFixed(7)
      : +watch("amount");

  const equalItemsAmountToAllAmount =
    totalItemsAmount.toFixed(2) === amountWithoutTax.toFixed(2);

  const amountWithTax =
    +watch("include_tax") === 1
      ? +(+watch("amount")).toFixed(5)
      : +(+watch("amount") + +watch("tax")).toFixed(5);

  const itemDPercentage = (index: number) => {
    const itemId = watch("items")?.[index]?.item?.id;
    const itemData = itemsData?.data.find((item: any) => item.id === itemId);

    if (
      !itemData ||
      itemData.last_amount === null ||
      itemData.last_quantity === null
    ) {
      return 0;
    }

    const currentAmount =
      +watch("items_include_tax") === 1
        ? +watch("items")?.[index].amount / 1.15
        : +watch("items")?.[index].amount || 0;
    const currentQuantity = +watch("items")?.[index].quantity || 0;
    const lastAmount = itemData?.last_amount ?? 0;
    const lastQuantity = itemData?.last_quantity ?? 1; // Default to 1 to avoid division by zero

    // Check if currentQuantity or lastQuantity is zero to avoid division by zero
    if (currentQuantity === 0 || lastQuantity === 0) {
      return 0;
    }

    // Calculate percentage change
    const lastAmountPerQuantity = lastAmount / lastQuantity;
    const currentAmountPerQuantity = currentAmount / currentQuantity;

    // Avoid division by zero in percentage calculation
    const percentageChange =
      ((currentAmountPerQuantity - lastAmountPerQuantity) /
        lastAmountPerQuantity) *
      100;

    return percentageChange || 0;
  };

  const onClose = () => {
    handleClose();
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleAppendRole = () => {
    append({
      payment_method_id: "",
      amount: "",
    });
  };

  const handleRemovePayment = (index: number) => {
    remove(index);
  };

  const handleAppendItem = () => {
    appendItem({
      amount: "",
      quantity: "",
      item: { id: "", unit: { name: "" } },
    });
  };

  const handleRemoveItem = (index: number) => {
    removeItem(index);
  };

  const goBack = () => {
    if (step === 1) {
      setStep(0);
      return;
    }
    onClose();
  };

  const validate = async () => {
    let stepFields = steps[step].fields;
    const validStep = await trigger(stepFields as any[], { shouldFocus: true });
    return validStep;
  };

  const goNext = async () => {
    const valid = await validate();
    if (!valid) return;
    const data = getValues();

    // if (
    //   +data.is_payment_advanced === 1 &&
    //   data.purchase_event_amount &&
    //   +data.purchase_event_amount > totalPurchaseEventCreditRemaining
    // ) {
    //   setNotifications([
    //     ...notifications,
    //     {
    //       type: "error",
    //       message: `${t("eventCredit")}: ${totalPurchaseEventCreditRemaining}`,
    //     },
    //   ]);
    //   return;
    // }

    if (branchHasItemsFeature && step === 0) {
      setStep(1);
      return;
    }
    if (branchHasItemsFeature && step === 1 && data.items.length === 0) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: t("addItems"),
        },
      ]);
      return;
    }

    if (branchHasItemsFeature && step === 1 && !equalItemsAmountToAllAmount) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: t("totalItemsNeedEqualTotalTransactions"),
        },
      ]);
      return;
    }

    console.log({ data });
    const {
      tax,
      amount,
      note,
      invoice_number,
      date,
      include_tax,
      branch_id,
      supplier,
      payments,
      items,
      invoice_date,
      has_issue,
    } = data;

    const dataToSend: any = {
      type: row.type,
      status: has_issue ? "issue" : "confirmed",
      has_issue: has_issue,
      branch_id: +branch_id,
      owner_id: supplier?.id,
      include_tax: include_tax,
      amount: +include_tax === 1 ? +(+amount - +tax).toFixed(5) : +amount,
      tax: tax,
      ...(invoice_number && {
        invoice_number: invoice_number,
      }),
      ...(note && { note: note }),
      date: date,
      invoice_date: invoice_date,
    };

    const paymentsToSend: any[] = [];
    payments.forEach((payment) => {
      if (payment.payment_method_id && payment.amount && +payment?.amount > 0) {
        paymentsToSend.push(payment);
      }
    });

    mutateUpdate({
      ...dataToSend,
      payments: paymentsToSend,
      items: items.map((item) => {
        const itemAmountToSend =
          +watch("items_include_tax") === 1 ? +item.amount / 1.15 : item.amount;
        return {
          item_id: item.item.id,
          total: itemAmountToSend,
          quantity: item.quantity,
        };
      }),
      id: row.id,
    });
    // }
  };

  useEffect(() => {
    if (+watch("include_tax") === 1) {
      setValue(
        "tax",
        `${(((+getValues("amount") || 0) / 1.15) * 0.15).toFixed(2)}`
      );
    } else if (+watch("include_tax") === 0) {
      setValue("tax", `${((+getValues("amount") || 0) * 0.15).toFixed(2)}`);
    }
  }, [watch("include_tax"), watch("amount")]);

  // reset form with purchase to edit
  useEffect(() => {
    if (!!row && !!itemsData) {
      console.log({ row });
      reset({
        branch_id: row.branch.id,
        supplier: suppliersData?.data.find(
          (supp: any) => supp.id === row.owner?.id
        ),
        payments:
          row.payments.length > 0
            ? row.payments.map((pay: any) => {
                return {
                  payment_method_id: pay.payment_method?.id,
                  amount: pay.total,
                };
              })
            : [
                {
                  payment_method_id: "",
                  amount: "",
                },
              ],
        note: row.note || "",
        items: row.items.map((item: any) => {
          return {
            item: {
              id: item.item?.id,
              sku: item.item?.sku,
              name: item.item?.name,
              unit: {
                name: item.item?.unit?.name,
              },
            },
            quantity: item.quantity,
            amount: item.total,
          };
        }),
        // tax: "0",
        // new
        amount: row.include_tax ? row.total : row.amount,
        tax: row.tax,
        include_tax: row.include_tax ? 1 : 0,
        invoice_number: row.invoice_number || "",
        date: row.date,
        invoice_date: row.invoice_date,
        has_issue: row.has_issue,
        items_include_tax: 0,
      });

      // Set isResetting to true while form is resetting
      setIsResetting(true);
    }
  }, [row, open, suppliersData, itemsData]);

  // Reset the isResetting flag after the form is reset
  useEffect(() => {
    if (isResetting) {
      setIsResetting(false);
    }
  }, [isResetting]);

  //Update success and error handling
  useEffect(() => {
    if (statusUpdate === "success") {
      refetch();
      onClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("addedSuccessfully"),
        },
      ]);
    } else if (statusUpdate === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            errorUpdate?.data.errors?.[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [statusUpdate]);

  let paymentsCredit = 0;
  watch("payments").forEach((pay) => {
    paymentsCredit += Number(pay.amount) || 0;
  });

  const credit = +(amountWithTax - paymentsCredit) || 0;

  const selectedItemsIds = watch("items")?.map((item) => +item.item?.id);

  const options: any[] = supplierRelatedItemsData
    ? [
        ...supplierRelatedItemsData?.related.map((item: any) => ({
          ...item,
          category: "relatedItems",
        })),
        ...supplierRelatedItemsData?.other.map((item: any) => ({
          ...item,
          category: "otherItems",
        })),
      ]
    : [];

  console.log("purchase", { row });
  console.log({ errors }, getValues());

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing={3}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "100%", sm: "90%", lg: "80%" },
          bgcolor: "#FFF",
          border: "1px solid #D0D5DD",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={0}
        >
          <Typography color={"#101828"} fontSize={"20px"}>
            {t("addNewPurchasing")}
          </Typography>
          <IconButton onClick={handleOpenConfirm} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <form>
          {step === 0 ? (
            <>
              <Stack spacing={2}>
                {/* select Branches */}
                <FormControl fullWidth size="small" error={!!errors.branch_id}>
                  <InputLabel id="demo-simple-select-label">
                    {t("selectBranches")}
                  </InputLabel>
                  <Controller
                    name="branch_id"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        key={field.value}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={t("selectBranches")}
                        disabled={true}
                      >
                        {branchesData?.data?.map(({ id, name }) => {
                          return (
                            <MenuItem key={id} value={id}>
                              {name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  />
                  <FormHelperText id="my-helper-text">
                    {errors.branch_id?.message}
                  </FormHelperText>
                </FormControl>

                {/* select Suppler */}
                <FormControl fullWidth size="small" error={!!errors.supplier}>
                  <Controller
                    name="supplier"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        size="small"
                        {...field}
                        options={suppliersData?.data}
                        getOptionLabel={(option: any) => option?.name}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            error={!!errors.supplier}
                            helperText={errors.supplier?.message}
                            label={t("selectSuppler")}
                          />
                        )}
                        renderOption={(props, option: any, { selected }) => (
                          <li key={props.id} {...props}>
                            <Typography>{option.name}</Typography>
                          </li>
                        )}
                        onChange={(_, data) => {
                          console.log({ data });
                          field.onChange(data);
                        }}
                      />
                    )}
                  />
                </FormControl>

                {/* Amount */}
                <Stack direction={"row"} alignItems={"flex-start"} gap={1}>
                  <Box sx={{ width: 220 }}>
                    <Controller
                      name="amount"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          type="number"
                          fullWidth
                          label={t("amount")}
                          size="small"
                          {...field}
                          error={!!errors.amount}
                          helperText={errors.amount?.message}
                          InputLabelProps={{
                            shrink: field.value !== undefined ? true : false,
                          }}
                        />
                      )}
                    />
                  </Box>
                  <FormControl
                    fullWidth
                    size="small"
                    error={!!errors.include_tax}
                    sx={{
                      width: "fit-content",
                      ml: { xs: "auto", sm: "unset" },
                    }}
                  >
                    <Controller
                      name="include_tax"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          {...field}
                          key={field.value}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            value="1"
                            control={
                              <Radio
                                size="small"
                                icon={<CircleOutlinedIcon />}
                                checkedIcon={<CheckCircleIcon />}
                              />
                            }
                            label={t("includeTax")}
                            sx={{ color: "#98A2B3" }}
                          />
                          <FormControlLabel
                            value="0"
                            control={
                              <Radio
                                size="small"
                                icon={<CircleOutlinedIcon />}
                                checkedIcon={<CheckCircleIcon />}
                              />
                            }
                            label={t("excludeTax")}
                            sx={{ color: "#98A2B3" }}
                          />
                        </RadioGroup>
                      )}
                    />
                    <FormHelperText id="my-helper-text">
                      {errors.include_tax?.message}
                    </FormHelperText>
                  </FormControl>
                </Stack>
                {/* tax Optional */}
                <Controller
                  name="tax"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={t("taxOptional")}
                      fullWidth
                      size="small"
                      {...field}
                      error={!!errors.tax}
                      helperText={errors.tax?.message}
                    />
                  )}
                />
                {/* invoice number */}
                <Controller
                  name="invoice_number"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={t("invoiceNumberOptional")}
                      fullWidth
                      size="small"
                      {...field}
                      error={!!errors.invoice_number}
                      helperText={errors.invoice_number?.message}
                    />
                  )}
                />

                {/* payments */}
                {fields.map((paymentField, index) => {
                  return (
                    <Stack key={paymentField.id} direction={"row"} gap={1}>
                      <FormControl
                        fullWidth
                        size="small"
                        error={
                          errors.payments &&
                          !!errors.payments[index]?.payment_method_id
                        }
                      >
                        <InputLabel id="demo-simple-select-label">
                          {t("paymentMethodOptional")}
                        </InputLabel>
                        <Controller
                          name={`payments.${index}.payment_method_id`}
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              key={field.value}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label={t("paymentMethodOptional")}
                            >
                              {paymentsData?.data
                                ?.filter((pay) => pay.status)
                                .map(({ id, name }) => {
                                  return (
                                    <MenuItem key={id} value={`${id}`}>
                                      {name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          )}
                        />
                        <FormHelperText id="my-helper-text">
                          {errors.payments &&
                            errors.payments[index]?.payment_method_id?.message}
                        </FormHelperText>
                      </FormControl>

                      <Controller
                        name={`payments.${index}.amount`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            type="number"
                            label={t("amount")}
                            variant="outlined"
                            {...field}
                            error={
                              errors.payments &&
                              !!errors.payments[index]?.amount
                            }
                            helperText={
                              errors.payments &&
                              errors.payments[index]?.amount?.message
                            }
                            fullWidth
                            size="small"
                            InputProps={{
                              inputProps: { min: 0 },
                            }}
                          />
                        )}
                      />

                      <IconButton onClick={() => handleRemovePayment(index)}>
                        <img src={DeleteIcon} alt="" />
                      </IconButton>
                    </Stack>
                  );
                })}
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Button
                    variant="text"
                    size="small"
                    startIcon={<img src={AddMoreIcon} alt="" />}
                    onClick={handleAppendRole}
                    sx={{ alignSelf: "flex-start" }}
                  >
                    {t("addAnotherMethod")}
                  </Button>

                  <Typography>
                    {t("credit")}: {credit} {generalT("sar")}
                  </Typography>
                </Stack>

                <Box mt={2}>
                  <Typography mb={1}>{auditingT("clientNote")}</Typography>
                  <InfoBox variant="info" text={`${row.app_note || "--"}`} />
                </Box>

                <Controller
                  name="has_issue"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      sx={{ mt: 1.5, width: "fit-content" }}
                      control={<Checkbox {...field} color="primary" />}
                      label={t("hasIssue")}
                      checked={field.value}
                    />
                  )}
                />

                {/* note */}
                <Controller
                  name="note"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={t("notesOptional")}
                      fullWidth
                      size="small"
                      multiline
                      rows={2}
                      maxRows={3}
                      {...field}
                      error={!!errors.note}
                      helperText={errors.note?.message}
                    />
                  )}
                />

                <Stack direction={"row"} gap={2} mt={2}>
                  <Controller
                    name="invoice_date"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        type="date"
                        label={`${t("invoiceDate")}`}
                        fullWidth
                        size="small"
                        {...field}
                        error={!!errors.invoice_date}
                        helperText={errors.invoice_date?.message}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                  {/* due date */}
                  <Controller
                    name="date"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        type="date"
                        label={`${t("dueDate")} (${t(
                          "dateOfGoodsEntryIntoInventory"
                        )})`}
                        fullWidth
                        size="small"
                        {...field}
                        error={!!errors.date}
                        helperText={errors.date?.message}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Stack>
              </Stack>
              {/* files */}
              <Stack mt={2} gap={2}>
                {row?.files.map((attachment: any) => {
                  return (
                    <FileItem
                      key={attachment.id}
                      name={`${attachment.file.name} .${attachment.file.extension}`}
                      info={formatBytes(attachment.file.size)}
                      isDeletable={false}
                      isDownloadable={true}
                      onDownload={() => {
                        downloadFileFromUrl(
                          attachment.file.url,
                          attachment.file.name
                        );
                      }}
                    />
                  );
                })}
              </Stack>
              {/* {mode === "add" && (
                <Box width={"100%"} mt={2}>
                  <FilePondUploader
                    onUpload={(e) => setFiles(e)}
                    maxFiles={3}
                    maxFileSize={3}
                    acceptedFileTypes={[
                      "image/*",
                      "application/pdf",
                      "text/csv",
                      "application/vnd.ms-excel",
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    ]}
                  />
                </Box>
              )} */}
            </>
          ) : (
            <>
              <Stack direction={"row"} mb={3} alignItems={"center"} gap={10}>
                <Typography fontSize={"14px"} fontWeight={600}>
                  {t("addItems")}
                </Typography>
                <FormControl
                  fullWidth
                  size="small"
                  error={!!errors.items_include_tax}
                  sx={{
                    width: "fit-content",
                    ml: { xs: "auto", sm: "unset" },
                  }}
                  disabled={mode === "edit"}
                >
                  <Controller
                    name="items_include_tax"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup
                        {...field}
                        key={field.value}
                        row
                        aria-labelledby="buttons-group-label"
                        name="buttons-group"
                      >
                        <FormControlLabel
                          value={"1"}
                          control={
                            <Radio
                              size="small"
                              icon={<CircleOutlinedIcon />}
                              checkedIcon={<CheckCircleIcon />}
                            />
                          }
                          label={`${t("amount")} ${t("includeTax")}`}
                          sx={{ color: "#98A2B3" }}
                        />
                        <FormControlLabel
                          value={"0"}
                          control={
                            <Radio
                              size="small"
                              icon={<CircleOutlinedIcon />}
                              checkedIcon={<CheckCircleIcon />}
                            />
                          }
                          label={`${t("amount")} ${t("excludeTax")}`}
                          sx={{ color: "#98A2B3" }}
                        />
                      </RadioGroup>
                    )}
                  />
                  <FormHelperText id="my-helper-text">
                    {errors.items_include_tax?.message}
                  </FormHelperText>
                </FormControl>
              </Stack>

              <Stack gap={1.5}>
                {/* items */}
                {itemFields.map((itemField, index) => {
                  const itemAmount =
                    +watch("items_include_tax") === 1
                      ? +watch("items")?.[index].amount / 1.15
                      : +watch("items")?.[index].amount;
                  return (
                    <Stack
                      key={itemField.id}
                      direction={"row"}
                      gap={1}
                      alignItems={"center"}
                    >
                      <FormControl fullWidth size="small">
                        <Controller
                          name={`items.${index}.item`}
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              size="small"
                              {...field}
                              options={options.filter(
                                (item: any) =>
                                  !selectedItemsIds?.includes(item.id) ||
                                  item.id === +watch("items")?.[index]?.item
                              )}
                              groupBy={(option) => option.category}
                              getOptionLabel={(option: any) =>
                                option?.name || ""
                              }
                              renderGroup={(params) => (
                                <div key={params.key}>
                                  <Box px={2} py={0.5}>
                                    <Typography
                                      color={"textSecondary"}
                                      fontSize={"14px"}
                                    >
                                      {t(params.group)}
                                    </Typography>
                                  </Box>
                                  {params.children}
                                </div>
                              )}
                              renderInput={(params: any) => (
                                <TextField
                                  {...params}
                                  error={!!errors.items?.[index]?.item}
                                  helperText={
                                    errors.items?.[index]?.item?.message
                                  }
                                  label={t("item")}
                                />
                              )}
                              renderOption={(
                                props,
                                option: any,
                                { selected }
                              ) => (
                                <li key={props.id} {...props}>
                                  <Typography>{option.name}</Typography>
                                </li>
                              )}
                              onChange={(_, data) => {
                                console.log({ data });
                                field.onChange(data);
                              }}
                            />
                          )}
                        />
                      </FormControl>
                      <Box sx={{ flexBasis: "15%" }}>
                        <Typography color={"textSecondary"} fontSize={"14px"}>
                          {t("SKU")}
                        </Typography>
                        <Typography
                          color={"textSecondary"}
                          noWrap
                          fontSize={"14px"}
                          fontWeight={700}
                          whiteSpace={"nowrap"}
                        >
                          {watch("items")?.[index]?.item?.sku}
                        </Typography>
                      </Box>
                      <Box sx={{ flexBasis: "10%" }}>
                        <Typography color={"textSecondary"} fontSize={"14px"}>
                          {t("unit")}
                        </Typography>
                        <Typography
                          color={"textSecondary"}
                          noWrap
                          fontSize={"14px"}
                          fontWeight={700}
                          whiteSpace={"nowrap"}
                        >
                          {watch("items")?.[index]?.item?.unit?.name}
                        </Typography>
                      </Box>
                      <Controller
                        name={`items.${index}.quantity`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            type="number"
                            label={generalT("quantity")}
                            variant="outlined"
                            {...field}
                            error={!!errors.items?.[index]?.quantity}
                            fullWidth
                            size="small"
                            InputProps={{
                              inputProps: { min: 0 },
                            }}
                          />
                        )}
                      />
                      <Controller
                        name={`items.${index}.amount`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            type="number"
                            label={t("amountTotal")}
                            variant="outlined"
                            {...field}
                            error={!!errors.items?.[index]?.amount}
                            fullWidth
                            size="small"
                            InputProps={{
                              inputProps: { min: 0 },
                            }}
                          />
                        )}
                      />
                      {/* each item data */}
                      <Stack direction={"row"} gap={1} flexBasis={"60%"}>
                        {mode === "add" ? (
                          <Box px={"2px"}>
                            <Typography
                              color={"textSecondary"}
                              fontSize={"14px"}
                            >
                              D%
                            </Typography>
                            <Typography
                              color={
                                +itemDPercentage(index) < -2 ||
                                +itemDPercentage(index) > 2
                                  ? "error"
                                  : "textSecondary"
                              }
                              fontSize={"14px"}
                              fontWeight={700}
                            >
                              {itemDPercentage(index).toFixed(2)}%
                            </Typography>
                          </Box>
                        ) : null}
                        <Box px={"2px"}>
                          <Typography
                            noWrap
                            color={"textSecondary"}
                            fontSize={"14px"}
                          >
                            {t("unitCost")}
                          </Typography>
                          <Typography
                            color={"textSecondary"}
                            noWrap
                            fontSize={"14px"}
                            fontWeight={700}
                          >
                            {(
                              itemAmount / +watch("items")?.[index]?.quantity
                            ).toFixed(2)}{" "}
                            SAR
                          </Typography>
                        </Box>
                        <Box px={"2px"}>
                          <Typography color={"textSecondary"} fontSize={"14px"}>
                            {generalT("total")}
                          </Typography>
                          <Typography
                            color={"textSecondary"}
                            noWrap
                            fontSize={"14px"}
                            fontWeight={700}
                          >
                            {itemAmount.toFixed(2)} SAR
                          </Typography>
                        </Box>
                      </Stack>

                      <IconButton onClick={() => handleRemoveItem(index)}>
                        <img src={DeleteIcon} alt="" />
                      </IconButton>
                    </Stack>
                  );
                })}
                <Button
                  variant="text"
                  size="small"
                  startIcon={<img src={AddMoreIcon} alt="" />}
                  onClick={handleAppendItem}
                  sx={{ alignSelf: "flex-start" }}
                >
                  {t("addAnotherItem")}
                </Button>
                <Typography
                  color={equalItemsAmountToAllAmount ? "textPrimary" : "error"}
                >
                  {t("totalItems")}:{" "}
                  <Typography component={"span"} fontWeight={600}>
                    {totalItemsAmount.toFixed(2)} SAR
                  </Typography>
                </Typography>
                <Typography>
                  {t("totalTransactions")}:{" "}
                  <Typography component={"span"} fontWeight={600}>
                    {amountWithoutTax.toFixed(2)} SAR
                  </Typography>
                </Typography>
                <InfoBox text={t("totalItemsNeedEqualTotalTransactions")} />
              </Stack>
            </>
          )}

          <Stack spacing={2} direction={"row"} mt={3}>
            <Button
              variant="outlined"
              color="tertiary"
              fullWidth
              onClick={goBack}
            >
              {generalT(step === 1 ? "back" : "cancel")}
            </Button>
            <LoadingButton
              variant="contained"
              fullWidth
              onClick={goNext}
              loading={isPendingUpdate}
            >
              {step === 0 && branchHasItemsFeature
                ? t("next")
                : watch("has_issue")
                ? generalT("save")
                : t("addPurchasing")}
            </LoadingButton>
          </Stack>
        </form>
        {/* confirm close popup */}
        <ConfirmPopup
          open={openConfirm}
          handleClose={handleCloseConfirm}
          handleConfirm={handleClose}
          title={generalT("discard")}
          subtitle={generalT("areYouSureToDiscardChanges")}
          confirmBtnText={generalT("confirm")}
        />
      </Stack>
    </Modal>
  );
};

export default AddPurchasePopup;
