import { Button, IconButton, Stack, Tooltip } from "@mui/material";
import EditIcon from "src/assets/svg-icons/aggregators/edit.svg";
import DeleteIcon from "src/assets/svg-icons/aggregators/delete.svg";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AddEditTransactionPopup from "../../add-edit-purchasing";
import PurchaseAttachmentsPopup from "../../attachments-popup";
import AddPurchasePaymentPopup from "../../pay-popup";
import { ConfirmPopup } from "src/shared/components/confirm-popup";
import { useDeletePurchaseMutation } from "src/api/purchasing/purchasing";

interface IAction {
  row: any;
  refetch: Function;
}

const TableActions = (props: IAction) => {
  const { row, refetch } = props;
  const { id, reference } = row;
  const { t } = useTranslation("general");
  const navigate = useNavigate();
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [openDelete, setOpenDelete] = useState(false);
  const [openPay, setOpenPay] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAttachmentsModal, setOpenAttachmentsModal] = useState(false);

  // APIS
  const { mutate, isPending, status, error } = useDeletePurchaseMutation();

  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const handleOpenPay = () => setOpenPay(true);
  const handleClosePay = () => setOpenPay(false);

  const handleOpenAttachmentsModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setOpenAttachmentsModal(true);
  };

  const handleCloseAttachmentsModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setOpenAttachmentsModal(false);
  };

  const navigateToTransDetails = () => {
    navigate(`/purchasing/${id}`);
  };

  const handleDelete = () => {
    mutate(id);
  };

  useEffect(() => {
    if (status === "success") {
      refetch();
      handleCloseDelete();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: t("deletedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || t("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <Stack direction={"row"} spacing={"12px"} justifyContent={"flex-start"}>
      <Tooltip title={t("attachments")}>
        <IconButton onClick={handleOpenAttachmentsModal}>
          <img src={DownloadIcon} alt="" />
        </IconButton>
      </Tooltip>

      <Tooltip title={t("edit")} onClick={handleOpenEdit}>
        <IconButton>
          <img src={EditIcon} alt="" />
        </IconButton>
      </Tooltip>

      <Tooltip title={t("delete")}>
        <IconButton onClick={handleOpenDelete}>
          <img src={DeleteIcon} alt="" />
        </IconButton>
      </Tooltip>

      <Tooltip title={t("details")} onClick={navigateToTransDetails}>
        <IconButton>
          <VisibilityOutlinedIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      {row?.status !== "Paid" && (
        <Tooltip title={t("pay")}>
          <Button
            color="tertiary"
            size="small"
            variant="outlined"
            onClick={handleOpenPay}
          >
            {`${t("pay")}!`}
          </Button>
        </Tooltip>
      )}

      {/* confirm delete popup */}
      <ConfirmPopup
        open={openDelete}
        item={reference}
        handleClose={handleCloseDelete}
        handleConfirm={handleDelete}
        isPending={isPending}
      />
      {/* payment popup */}
      {openPay && (
        <AddPurchasePaymentPopup
          open={openPay}
          handleClose={handleClosePay}
          refetch={refetch}
          row={row}
        />
      )}

      {/* edit popup */}
      {openEdit && (
        <AddEditTransactionPopup
          open={openEdit}
          handleClose={handleCloseEdit}
          purchaseToEdit={row}
          refetch={refetch}
        />
      )}
      {/* attachments popup */}
      {openAttachmentsModal && (
        <PurchaseAttachmentsPopup
          open={openAttachmentsModal}
          handleClose={handleCloseAttachmentsModal}
          rowId={row.id}
        />
      )}
    </Stack>
  );
};
export default TableActions;
