import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import { Box, Typography } from "@mui/material";
import TableActions from "./table-actions";
import { formatNumber } from "src/utils";
import { useTranslation } from "react-i18next";

const TransactionDetailsTable = ({
  list,
  isLoading,
  refetch,
}: {
  list: any;
  isLoading: boolean;
  refetch: Function;
}) => {
  const { t } = useTranslation("operationCost");
  const { t: generalT } = useTranslation("general");
  const tableHeadText = [
    generalT("reference"),
    generalT("branch"),
    generalT("relatedPayments"),
    t("netPayment"),
    t("paymentDate"),
    generalT("createdBy"),
    generalT("addingSource"),
    generalT("createdAt"),
    generalT("actions"),
  ];
  const emptyDataArr = list.length === 0;

  return (
    <Box>
      <Typography mb={1}>{generalT("payments")}</Typography>
      <TableContainer component={Paper} variant="outlined">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeadText.map((item) => (
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: "18px",
                    color: "#475467",
                    bgcolor: "#F9FAFB",
                  }}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableLoadingSkeleton rowsLength={8} cellsLength={8} />
          ) : (
            <TableBody>
              {list?.map((row: any) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.reference}</TableCell>
                  <TableCell align="center">
                    {row.payment_method.branch?.name}
                  </TableCell>
                  <TableCell align="center">
                    {row.payment_method.name}
                  </TableCell>
                  <TableCell align="center">
                    {formatNumber(row.total)}
                  </TableCell>
                  <TableCell align="center">{row.date}</TableCell>
                  <TableCell align="center">{row.created_by?.name}</TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">{row.created_at}</TableCell>
                  <TableCell align="center">
                    <TableActions
                      rowId={row.id}
                      rowRef={row.reference}
                      refetch={refetch}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {emptyDataArr && <NoData />}
      </TableContainer>
    </Box>
  );
};
export default TransactionDetailsTable;
