import { Stack } from "@mui/material";
import EquationItem from "src/shared/components/equation-item";
import { useTranslation } from "react-i18next";
import { formatNumber } from "src/utils";

const Equations = ({ data }: { data: any }) => {
  const { t } = useTranslation("inventory");
  const { t: dashboardT } = useTranslation("dashboard");

  const EquationItems = [
    {
      title: `${t("sar")} ${formatNumber(data.direct_cost)}`,
      subtitle: t("directCost"),
      subtitleColor: "#98A2B3",
      operator: "+",
      tooltip: `${t("netSales")} + ${t("tax")}`,
    },
    {
      title: `${t("sar")} ${formatNumber(data.indirect_cost)}`,
      subtitle: t("indirectCost"),
      subtitleColor: "#98A2B3",
      operator: "=",
      tooltip: "",
    },
    {
      title: `${t("sar")} ${formatNumber(data.total_cost)}`,
      subtitle: dashboardT("cogs"),
      subtitleColor: "#98A2B3",
      tooltip: "",
    },
  ];
  return (
    <Stack py={"10px"} bgcolor={"#EAECF0"} direction={"row"} flexWrap={"wrap"}>
      {EquationItems.map((item) => (
        <EquationItem
          title={item.title}
          subtitle={item.subtitle}
          subtitleColor={item.subtitleColor}
          operator={item.operator}
          tooltip={item.tooltip}
        />
      ))}
    </Stack>
  );
};
export default Equations;
